import { createStore } from 'vuex'

export default createStore({
  state() {
    return {
      userInfo:'',
      loginFlag:"",
    }
  },
  getters: {
  },
  mutations: {
    changeUserInfo(state,data){
      state.userInfo=data
    },
    setLoginFlag(state,data){
      state.loginFlag=data
    }
  },
  actions: {
  },
  modules: {
  }
})
