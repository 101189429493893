import { createRouter, createWebHashHistory } from 'vue-router'
import local from "@/utils/local"
import { Toast } from "vant";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/UserLogin.vue'),
  },
  {
    path: '/index',
    name: 'index',
    meta: {
      title: '米粒盒子',
    },
    component: () => import('@/views/UserIndex.vue'),
  },
  {
    path: '/detailed',
    meta: {
      title: '收支明细',
    },
    component: () => import('@/views/UserDetailed.vue'),
  },
  {
    path: '/invitation',
    meta: {
      title: '邀请用户',
    },
    component: () => import('@/views/UserInvitation.vue'),
  },
  {
    path: '/twoDimensional',
    meta: {
      title: '微信邀请码',
    },
    component: () => import('@/views/UserTwoDimensional.vue'),
  },
  {
    path: '/poster',
    meta: {
      title: '分享海报',
    },
    component: () => import('@/views/UserPoster.vue'),
  },
  {
    path: '/gitOpenId',
    name:'gitOpenId',
    meta: {
      title: '授权',
    },
    component: () => import('@/views/gitOpenId.vue'),
  },
  {
    path: '/gitOpenIdUser',
    name:'gitOpenIdUser',
    meta: {
      title: '授权',
    },
    component: () => import('@/views/gitOpenIdUser.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
// 路由导航守卫
router.beforeEach((to, from, next) => {
  console.log(from)
  if(to.path === "/gitOpenId"){
    document.title = to.meta.title
      next()
  }else{
    let loginTime = local.get("loginTime")
    if (loginTime) {
      if (from.path === "/") {
        let newTime = new Date().getTime()
        const formatTimeDiff = (newTime - loginTime) / (3600 * 1000)
        if (formatTimeDiff > 24) {
          Toast('登录已过期,请重新登录!')
          local.clear()
          if (to.fullPath == "/login") {
            document.title = to.meta.title
            next()
          } else {
            document.title = to.meta.title
            next({ name: "login" })
          }
        } else {
          console.log(1)
          if (to.fullPath == "/index") {
            document.title = to.meta.title
            next()
          } else {
            document.title = to.meta.title
            next({ name: "index" })
          }
        }
      } else {
        document.title = to.meta.title
        next()
      }
    }else {
      console.log(to)
      document.title = to.meta.title
      if (to.fullPath == "/login") {
        document.title = to.meta.title
        next()
      } else {
        document.title = to.meta.title
        next()
      }
    }
  }

})


export default router
