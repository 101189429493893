// 封装本地存储的工具js

export default {
    // 存
    set(key,value){
        return localStorage.setItem(key,JSON.stringify(value));
    },
    // 取
    get(key){
        return JSON.parse(localStorage.getItem(key));
    },
    // 删一个
    remove(key){
        return localStorage.removeItem(key)
    },
    // 清空
    clear(){
        return localStorage.clear();
    }
}

